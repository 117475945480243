<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link :to="'/module/question-solution/category/' + categoryID + '/book'" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.questionSolution.book.title") }}
        </router-link>
    </div>
    <custom-table
        :runModeServer="false"
        :title="sprintf($t('pages.module.questionSolution.book.chapter.titlePattern'), [book.title ?? ''])"
        :subTitle="$t('pages.module.questionSolution.book.chapter.subTitle')"
        expandableRows
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :pagination="table.pagination"
        :columns="fields"
        :actions="actions"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow">
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.module.questionSolution.book.chapter.question.title')" placement="top" v-if="!record.children_count">
                    <router-link :to="sprintf('/module/question-solution/category/%d/book/%d/chapter/%d/question', [categoryID, bookID, record.id])" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/text/txt009.svg" />
                        </span>
                    </router-link>
                </el-tooltip>
                <a v-on:click="newRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                    </span>
                </a>
                <a v-on:click="fetchRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_chapter" ref="chapterModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="chapterForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.questionSolution.book.chapter.cols.title') }}</label>
                                <el-form-item prop="title" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.title" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                                <el-form-item prop="sort" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.sort" :min="1" />
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-10">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                <el-form-item prop="active">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            table: {
                loading: false,
                data: [],
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
            },
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.questionSolution.book.chapter.cols.title"),
                    key: "title",
                },
                {
                    name: this.$t("pages.module.questionSolution.book.chapter.cols.questionCount"),
                    key: "questions_count",
                    class: ""
                },
                {
                    name: this.$t("common.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            book: {},
            selectedRowKeys: []
        }
    },
    computed: {
        bookID() {
            return this.$route.params.book_id;
        },
        categoryID() {
            return this.$route.params.id;
        }
    },
    created() {
        if (!this.bookID || !(this.bookID > 0)) {
            this.$router.push({
                path: this.sprintf('/module/question-solution/category/%d/book', [this.categoryID])
            });
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.questionSolution.book.chapter.title"), [this.$t("menu.moduleManagement")]);

        if (this.bookID && this.bookID > 0) {
            this.loadTable();
            this.loadBook();
        }
    },
    methods: {
        loadTable(){
            this.table.loading = true;

            this.axios.get(this.endpoints['module_question_solution_book_chapter_tree'], {
                params: {
                    book_id: this.bookID
                }
            }).then((response) => {
                let data = response.data.data;

                this.table.data = data;
                this.table.pagination.total = data.length;
            }).finally(() => {
                this.table.loading = false;
            });
        },
        loadBook(){
            this.axios.get(this.endpoints['module_question_solution_book'] + '/' + this.bookID).then((response) => {
                let data = response.data.data;
                this.book = data;
            }).catch((error) => {
                if(error.response.data.message == 'record_not_found') {
                    this.$router.push({
                        path: this.sprintf('/module/question-solution/category/%d/book', [this.categoryID])
                    });
                }
            });
        },
        newRecord(record = null){
            this.form.updateStatus = false;

            this.form.data = {
                book_id: this.bookID,
                sort: 1,
                active: true
            };

            this.form.title = this.$t("pages.module.questionSolution.book.chapter.new");

            if(record) {
                this.form.title = this.sprintf(this.$t("pages.module.questionSolution.book.chapter.newWithParent"), [record.title]);
                this.form.data.parent_id = record.id;
            }

            this.showModal(this.$refs.chapterModal);
        },
        fetchRecord(record){
            this.form.updateStatus = true;
            this.axios.get(this.endpoints['module_question_solution_book_chapter'] + '/' + record.id).then(response => {
                let data = response.data.data;

                this.form.title = this.$t("pages.module.questionSolution.book.chapter.edit");
                this.form.data = data;

                this.showModal(this.$refs.chapterModal);
            });
        },
        onSubmit(){
            this.$refs.chapterForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.axios.put(this.endpoints['module_question_solution_book_chapter'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadTable();
                                this.hideModal(this.$refs.chapterModal);
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data);
                        }).finally(() => {
                            this.form.loading = false;
                        });
                    }else {
                        this.axios.post(this.endpoints['module_question_solution_book_chapter'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadTable();
                                this.hideModal(this.$refs.chapterModal);
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data);
                        }).finally(() => {
                            this.form.loading = false;
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id){
            this.$store.dispatch("module/questionSolution/book/chapter/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
                if(successDeleted) {
                    this.loadTable();
                }
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.loadTable();
                    break;

                case "new":
                    this.newRecord();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        }
    }
}
</script>

<style>
</style>